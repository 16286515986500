import { DubButton } from "../common/button";
import Maintenance from "./Maintenance";

const STRINGS = {
  title: {
    en: "The page could not be found",
    ko: "페이지를 찾을 수 없습니다.",
    ja: "ページが見つかりません。",
  },
  body: {
    en: "It might have been removed, renamed, or did not exist in the first place.",
    ko: "존재하지 않는 주소를 입력하셨거나, 삭제되어 찾을 수 없습니다.",
    ja: "存在しないアドレスを入力したか、削除されました。",
  },
  home: {
    en: "Home",
    ko: "홈으로",
    ja: "ホーム",
  },
};

const NotFound = ({ locale = "en" }: { locale: "en" | "ko" | "ja" }) => {
  return (
    <Maintenance
      img="/img/maintenance/404.png"
      width={236}
      height={150}
      title={STRINGS.title[locale]}
      body={STRINGS.body[locale]}
      button={
        <DubButton
          color="magenta"
          size="regular"
          onClick={() => window.location.replace("/")}
        >
          {STRINGS.home[locale]}
        </DubButton>
      }
    />
  );
};

export default NotFound;
