import NextErrorComponent from "next/error";
import NotFound from "@/modules/maintenance/NotFound";
import Stopped from "@/modules/maintenance/Stopped";
import getLocales from "@/modules/utils/getLocales";
import { NextPageContext } from "next";
import * as Sentry from "@sentry/nextjs";

const Error = ({ locale, statusCode, hasGetInitialPropsRun, err }: any) => {
  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(err);
    // Flushing is not required in this case as it only happens on the client
  }

  if (statusCode === 404) {
    return <NotFound locale={locale} />;
  }

  return <Stopped locale={locale} />;
};

// Error.getInitialProps = async (context: NextPageContext) => {
//   const errorInitialProps = await NextErrorComponent.getInitialProps(context);
//   errorInitialProps.hasGetInitialPropsRun = true;
//   const { req, err, query } = context;

//   const option = {
//     header: true,
//     cookie: true,
//     query: true,
//   };

//   let locale = "en";
//   if (req) {
//     locale = getLocales({ req, query }, option);
//   }

//   const { statusCode } = errorInitialProps;

//   if (err && statusCode !== 404) {
//     Sentry.captureException(err);
//     await Sentry.flush(2000);
//   }

//   return { locale, ...errorInitialProps };
// };

export default Error;
